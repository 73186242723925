import React from 'react'

const SunIcon = ({ className, ...props }) => {
  return (
    <svg width="23px" height="24px" viewBox="0 0 23 24" version="1.1" className={`fill-current ${className}`} {...props}>
      <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1071.000000, -658.000000)" fill="#FFFFFF">
          <g transform="translate(1071.000000, 658.254335)">
            <rect x="10.38125" y="0" width="1.2375" height="3.85"></rect>
            <rect x="10.38125" y="17.875" width="1.2375" height="4.125"></rect>
            <rect x="18.15" y="10.38125" width="3.85" height="1.2375"></rect>
            <rect x="0" y="10.38125" width="4.125" height="1.2375"></rect>
            <rect transform="translate(17.285203, 16.866156) rotate(135.000000) translate(-17.285203, -16.866156) " x="16.7077082" y="15.2230471" width="1.15498892" height="3.28621848"></rect>
            <rect transform="translate(5.133530, 4.708292) rotate(135.000000) translate(-5.133530, -4.708292) " x="4.55603538" y="3.06174513" width="1.15498892" height="3.29309342"></rect>
            <rect transform="translate(17.282030, 4.715205) rotate(45.000000) translate(-17.282030, -4.715205) " x="16.7045354" y="3.07209576" width="1.15498892" height="3.28621848"></rect>
            <rect transform="translate(5.126315, 16.862805) rotate(45.008103) translate(-5.126315, -16.862805) " x="4.54882041" y="15.2162587" width="1.15498894" height="3.29309345"></rect>
            <path d="M11,16.21125 C8.12625,16.21125 5.78875,13.87375 5.78875,11 C5.78875,8.12625 8.12625,5.78875 11,5.78875 C13.87375,5.78875 16.21125,8.12625 16.21125,11 C16.21125,13.87375 13.87375,16.21125 11,16.21125 L11,16.21125 Z M11,6.950625 C8.765625,6.950625 6.950625,8.765625 6.950625,11 C6.950625,13.234375 8.765625,15.049375 11,15.049375 C13.234375,15.049375 15.049375,13.234375 15.049375,11 C15.049375,8.765625 13.234375,6.950625 11,6.950625 L11,6.950625 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SunIcon
