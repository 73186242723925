import React from 'react'
import { Link } from 'gatsby'

import { INTERNAL_URLS } from '../../../config/urls'

import { ServicesSectionImg } from '../shared/ServicesSectionImg'
import { ServicesLayout } from '../shared/ServicesLayout'
import { ServicesHeader } from '../shared/ServicesHeader'
import { ServicesChecklist } from '../shared/ServicesChecklist'
import { ServicesSectionText } from '../shared/ServicesSectionText'
import { ServicesListCard } from '../shared/ServicesListCard'
import { Card } from 'components/common/Card'
import { Stages } from 'components/common/Stages'
import { Question } from '../../common/Question'
import SunIcon from '../../svg/SunIcon'

import MenopauseHeaderImg from 'components/images/menopause-img.jpg'
import WomanRockImg from 'components/images/menopause-img-4.jpg'
import OlderWomenImg from 'components/images/menopause-img-2.png'

import styles from './Menopause.module.scss'

// TODO: Update copy
const MENOPAUSE_SERVICES_LIST = [
  <>
    Non-hormonal Therapies{' '}
    <span className="text-xs italic">
      such as supplements and other recommendations
    </span>
  </>,
  'Bioidentical Hormone Therapy',
  'BioTE Pellet Therapy (Hormone Therapy)',
  'Traditional Hormone Therapy'
]

export const MenopauseServices = () => {
  return (
    <ServicesLayout>
      <ServicesHeader
        title="Menopause & Beyond"
        description="We offer personalized care as your body transitions through the phases of menopause."
        image={
          <ServicesSectionImg
            src={MenopauseHeaderImg}
            alt="Menopause Woman"
            direction="right"
            customStyles={{ container: 'mt-10 md-mt-0' }}
          />
        }
      />
      <section className="flex flex-wrap w-full justify-between mb-12 md-mb-12">
        <div className="mb-8 md-pr-16 w-full md-w-1/2">
          <ServicesSectionImg
            src={WomanRockImg}
            alt="Woman standing on rock"
            direction="left"
            customStyles={{ container: 'hidden md-block w-full md-w-full mb-10' }}
          />
        </div>
        <div className="w-full md-w-1/2 md-pl-12">
          <ServicesSectionText title="The Wise-Woman Years" className="md-w-full mb-20">
            <div className="flex flex-col space-y-4">
              <p>
                Welcome to your “wise-woman years”.
              </p>
              <p>
                Perimenopause and menopause are the stages of a woman’s life when her monthly period stops, along with other physical and emotional changes that are often overlooked.
              </p>
              <p>
                As a woman’s reproductive years end, this is a beginning of a new stage of life and we are here for you every step of the way.
              </p>
            </div>
          </ServicesSectionText>
        </div>
      </section>

      <section className="flex flex-wrap w-full justify-between">
        <div className="mb-8 md-pr-16 w-full md-w-1/2">
          <ServicesSectionText title="Perimenopause vs Menopause" className="md-w-full mb-20">
            <div className="flex flex-col space-y-4">
              <p>
                <b>Perimenopause</b> is the first, transitional stage in the process with an average length of 4 years. Though for some women, this may begin 8-10 years before menopause.
              </p>
              <p>
                During this stage, the ovaries make less estrogen, menstrual cycles start to change (becoming irregular and then stopping) and you may experience physical changes. Some common side effects are breast tenderness, worsening PMS and irregular/skipping periods.
              </p>
              <p className="pt-6">
                <b>Menopause</b> is when a woman no longer has menstrual periods for at least 12 months, which usually occurs for women in the late 40s to early 50s. At this point, ovaries are no longer producing high levels of hormones.
              </p>
              <p>
                During this time, some common symptoms may include hot flashes, night sweats, sleeping problems, viginal dryness, urinary urgency, emotional changes (irritability, mood swings, depression), dry skin and lack of sexual desire.
              </p>
              <p>
                Other troublesome symptoms of menopause can be called "senior moments" or "bad hair days", where our brains might not seem to work as well.  Some believe this can be magnified by the lack of hormones.
              </p>
            </div>
          </ServicesSectionText>
        </div>
        <div className="w-full md-w-1/2 md-pl-12">
          <ServicesSectionImg
            src={OlderWomenImg}
            alt="Older women walking"
            direction="right"
            customStyles={{ container: 'w-full md-w-full mb-20' }}
          />
        </div>
      </section>

      <ServicesListCard
        className="bg-primary-300 text-white mb-12 md-mb-24"
        title="Our Menopause Management"
        items={MENOPAUSE_SERVICES_LIST}
        icon={<SunIcon style={{ transform: 'translateY(1px)' }} />}
      />

      <section className="flex flex-wrap w-full justify-between mb-12 md-mb-24">
        <Card component="article" className="w-full md-flex-1 mb-8 md-mb-0 md-mr-5">
          <Card.Header className={styles.cardHeader}>
            Non-Hormonal Therapies
          </Card.Header>

          <div className="text-sm text-primary-500">
            <div className={styles.cardText}>
              <p>
                We offer menopausal guidance and advice, ranging from non-hormonal supplements to dietary and lifestyle recommendations.
              </p>
            </div>
          </div>

          <Card.Header className={styles.cardHeader}>
            Bioidentical Therapy
          </Card.Header>

          <div className="text-sm text-primary-500">
            <div className={styles.cardText}>
              <p>
                Plant-based, "weaker" and yet effective use of all female hormones.  Considered a "natural" solution, it solves deficiencies by supplementing hormones identical to our own body's hormones.
              </p>
            </div>
          </div>
        </Card>
        <Card component="article" className="w-full md-flex-1 md-ml-5">
          <Card.Header className={styles.cardHeader}>
            BioTE Pellet Therapy (Hormone Therapy)
          </Card.Header>

          <div className="text-sm text-primary-500">
            <div className={styles.cardText}>
              <p>
                Millions of individuals are impacted by hormone imbalance every day. From fatigue to weight gain to severe mood changes to low libido, many are left with feelings of frustration and discomfort, unaware of how they may alleviate these common issues.
              </p>
              <p>
                Dr. Izu specializes in addressing these and other indicators of aging through precise personalized patient care plans that optimize hormones and improve quality of life.
              </p>
            </div>
          </div>

          <Card.Header className={styles.cardHeader}>
            Traditional Hormone Therary
          </Card.Header>

          <div className="text-sm text-primary-500">
            <div className={styles.cardText}>
              <p>
                FDA-approved hormones therapy that has been proven to be effective.  Our practice strives to prescribe the lowest and yet most effective doses of hormones.
              </p>
            </div>
          </div>
        </Card>
      </section>

      <div className="mb-40">
        <Question title="What age do women go through menopause?">
          Definition of menopause is the absence of regular bleeding for 1 year. Menopause usually starts between the ages of 48 to 52 years. It can depend on family history also.
        </Question>

        <Question title="What are the most common symptoms of menopause?">
          <p>
            Most women feel subtle changes with their moods, body temperature changes(hot flashes) and sleep disturbances (or insomnia).
          </p>
          <p>
            Most commonly, difficulty sleeping through the night combined with waking up in the middle of the night and difficulty falling back to sleep are common first symptoms. As a woman goes through the menopausal phase, dryness in the vagina can be a bothersome symptom. Shifting weights to abdomen and buttocks are sometimes mentioned as other bothersome problems.
          </p>
        </Question>

        <Question title="Are there non hormonal treatments for menopause?">
          Yes. Certain herbal and over the counter therapies can greatly reduce the annoying symptoms of menopause as well as vaginl dryness and diminished sexual desire.
        </Question>

        <Question title="Are you offering non traditional hormonal therapies?">
          Yes. We offer bioidenticals hormone therapies and the Pellet therapies.
        </Question>
      </div>
    </ServicesLayout>
  )
}
