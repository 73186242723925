import React from 'react'

import Layout from '../../components/layout'
import { MenopauseServices } from '../../components/Services/Menopause'

const MenopausePage = () => {
  return (
    <Layout seo={{ title: 'Menopause' }}>
      <MenopauseServices />
    </Layout>
  )
}

export default MenopausePage
